import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { LearningGoal } from '@/app/personalize-learning-journey/types';

interface LearningJourneyState {
  jobsToBeDone?: string[];
  learningGoal: LearningGoal;
  learningApproach: string;
  jobFunction?: string;
  jobTitle?: string;
  selectedItems: string[] | null;
  allItemsSelected?: boolean;
  isReset?: boolean;
}

export const learningJourneyInitialState: LearningJourneyState = {
  learningGoal: { id: '', name: '' },
  learningApproach: '',
  jobFunction: '',
  jobTitle: '',
  selectedItems: null,
  allItemsSelected: false,
  isReset: false,
};

const learningJourneySlice = createSlice({
  name: 'learningJourney',
  initialState: learningJourneyInitialState,
  reducers: {
    setJobsToBeDone: (state, action: PayloadAction<string>) => {
      state.jobsToBeDone = state.jobsToBeDone?.includes(action.payload)
        ? state.jobsToBeDone.filter((job) => job !== action.payload)
        : [...(state.jobsToBeDone || []), action.payload];
    },
    setLearningGoal: (state, action: PayloadAction<LearningGoal>) => {
      state.learningGoal = action.payload;
    },
    setLearningApproach: (state, action: PayloadAction<string>) => {
      state.learningApproach = action.payload;
    },
    setJobFunction: (state, action: PayloadAction<string>) => {
      state.jobFunction = action.payload;
    },
    setJobTitle: (state, action: PayloadAction<string>) => {
      state.jobTitle = action.payload;
    },
    setSelectedItems: (state, action: PayloadAction<string | string[] | null>) => {
      if (action.payload === null) {
        state.selectedItems = null;
      } else if (Array.isArray(action.payload)) {
        state.selectedItems = action.payload;
      } else if (state.selectedItems?.includes(action.payload)) {
        state.selectedItems = state.selectedItems.filter((item) => item !== action.payload);
      } else {
        state.selectedItems = [...(state.selectedItems || []), action.payload];
      }
    },
    setAllItemsSelected: (state, action: PayloadAction<boolean>) => {
      state.allItemsSelected = action.payload;
    },
    setLearningJourneyState: (state, action: PayloadAction<LearningJourneyState>) => {
      Object.assign(state, action.payload);
    },
    setIsReset: (state, action: PayloadAction<boolean>) => {
      state.isReset = action.payload;
    },
  },
});

const { actions, reducer } = learningJourneySlice;

export const {
  setJobsToBeDone,
  setLearningGoal,
  setLearningApproach,
  setJobFunction,
  setJobTitle,
  setSelectedItems,
  setAllItemsSelected,
  setLearningJourneyState,
  setIsReset,
} = actions;

export default reducer;
