'use client';

import React, { useState, useMemo } from 'react';
import { IdsNotificationContainer, IdsNotification } from '@emergn-infinity/ids-react';

import { NotificationContext, emptyNotification } from './notification-context';

const NotificationProviderWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notification, setNotification] = useState(emptyNotification);

  const openSuccessNotification = (
    message: string,
    customActionLabel?: string,
    customAction?: Function,
    customTitle?: string,
  ) => {
    setNotification({
      isOpen: true,
      variant: 'success',
      heading: customTitle ?? 'Success!',
      description: message,
      customActionLabel,
      customAction,
    });
  };

  const openErrorNotification = (
    message: string,
    customActionLabel?: string,
    customAction?: Function,
    customTitle?: string,
  ) => {
    setNotification({
      isOpen: true,
      variant: 'critical',
      heading: customTitle || 'Oops!',
      description: message,
      customActionLabel,
      customAction,
    });
  };

  const cleanNotification = () => {
    setNotification(emptyNotification);
  };

  const handleCloseComponent = (ev: any) => {
    if (notification.isOpen) {
      // we only want to trigger the custom action if the user clicks the button (not when the notification closes automatically)
      if (ev.type === 'click' && notification.customAction) {
        notification.customAction();
      }
      cleanNotification();
    }
  };

  const contextValue = useMemo(
    () => ({
      notification,
      openSuccessNotification,
      openErrorNotification,
      cleanNotification,
    }),
    [notification],
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {notification.isOpen && (
        <IdsNotificationContainer>
          <IdsNotification
            {...notification}
            closeButton={{ label: notification.customActionLabel ?? 'Close' }}
            closeHandler={handleCloseComponent}
            data-elp-testid={`${notification.variant === 'success' ? 'success-banner' : 'error-banner'}`}
          />
        </IdsNotificationContainer>
      )}
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProviderWrapper;
