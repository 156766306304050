import { createTheme, Theme } from '@mui/material/styles';
import styles from './theme.module.scss';

export const theme: Theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        root: {
          height: '8px',
          padding: '0',
          color: styles.primaryWhite,

          '&:hover span.MuiSlider-thumb': {
            width: '16px',
            height: '16px',
            marginTop: '-4px',
          },

          '&.MuiSlider-vertical': {
            padding: '0',
            color: styles.primaryWhite,

            '& .MuiSlider-rail': {
              width: '8px',
            },

            '& .MuiSlider-track': {
              width: '8px',
              borderRadius: '2px 2px 5px 5px',
            },

            '& .MuiSlider-thumb': {
              marginLeft: '0px',
            },

            '&:hover span.MuiSlider-thumb': {
              marginLeft: '-4px',
            },
          },
        },

        rail: {
          height: '8px',
          borderRadius: '5px',
          backgroundColor: 'rgba(244, 244, 244, 0.4)',
          opacity: 1,
        },
        track: {
          height: '8px',
          borderRadius: '5px 2px 2px 5px;',
        },
        thumb: {
          width: '8px',
          height: '8px',
          marginTop: '0px',

          '&:focus, &:hover, &$active': {
            boxShadow: '0px 0px 0px 8px rgb(255 255 255 / 30%)',
          },
        },
      },
    },
  },
});
